import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%"  viewBox="0 0 1080.000000 1008.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g 
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd" transform="translate(0.000000,1008.000000) scale(0.100000,-0.100000)">


<path d="M5728 5378 l-3 -1102 -95 -23 c-52 -12 -121 -25 -155 -27 -34 -3 -47
-2 -30 2 l30 7 3 863 2 862 -310 0 -310 0 0 -864 0 -865 23 -4 c12 -3 -1 -3
-30 -1 -71 5 -236 44 -245 58 -4 6 -8 194 -8 419 l0 407 -310 0 -310 0 0 -265
c0 -146 -4 -265 -8 -265 -16 0 -151 106 -242 189 -50 46 -125 125 -167 177
-42 52 -78 92 -80 90 -11 -10 110 -265 171 -358 184 -285 442 -513 740 -653
259 -122 491 -175 769 -175 309 0 569 63 837 203 367 192 656 506 821 891 41
96 36 108 -15 39 -84 -110 -401 -403 -437 -403 -5 0 -9 420 -9 933 -1 759 -3
935 -14 950 -12 15 -39 17 -315 17 l-301 0 -2 -1102z"/>
<path d="M2985 2157 c-90 -30 -169 -101 -209 -186 -17 -37 -21 -66 -21 -141 0
-84 3 -102 28 -152 35 -71 110 -140 183 -167 66 -25 168 -28 242 -6 63 19 140
79 170 133 l23 42 -63 0 c-60 0 -66 -2 -87 -31 -35 -48 -101 -73 -177 -66
-136 10 -214 101 -214 247 0 113 58 204 148 235 93 31 174 14 236 -51 41 -42
45 -44 99 -44 31 0 57 4 57 8 0 14 -64 100 -90 120 -61 48 -113 66 -200 69
-52 2 -101 -2 -125 -10z"/>
<path d="M3540 1825 l0 -335 50 0 50 0 0 150 0 150 160 0 160 0 0 -150 0 -150
55 0 55 0 0 335 0 335 -55 0 -55 0 0 -145 0 -146 -157 3 -158 3 -3 143 -3 142
-49 0 -50 0 0 -335z"/>
<path d="M4220 1825 l0 -335 180 0 180 0 0 45 0 45 -130 0 -130 0 0 105 0 105
120 0 120 0 0 39 c0 26 -5 41 -16 45 -9 3 -63 6 -120 6 l-104 0 0 100 0 100
130 0 130 0 0 40 0 40 -180 0 -180 0 0 -335z"/>
<path d="M4903 2072 c-69 -192 -203 -571 -203 -576 0 -4 24 -6 53 -6 l53 0 24
70 24 70 146 0 146 0 23 -70 22 -70 55 0 c30 0 54 2 54 5 0 2 -29 85 -64 182
-35 98 -88 247 -118 330 l-53 153 -65 0 -65 0 -32 -88z m152 -189 c26 -76 49
-144 52 -150 3 -10 -21 -13 -106 -13 -61 0 -111 4 -111 9 0 5 6 24 13 42 8 19
31 82 52 142 20 59 41 107 45 107 5 0 29 -62 55 -137z"/>
<path d="M5410 1825 l0 -335 50 0 49 0 3 138 3 137 116 5 c114 5 117 6 162 40
92 70 105 202 29 284 -52 56 -91 66 -259 66 l-153 0 0 -335z m269 249 c52 -11
81 -46 81 -98 0 -96 -40 -126 -169 -126 l-81 0 0 115 0 115 69 0 c38 0 82 -3
100 -6z"/>
</g>
</svg>




    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
